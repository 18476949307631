import { IColleagueEntity } from '../../../interfaces/api/messaging-entity';
import {
  messageableFactory as actionableFactory,
  alertStateAdapter,
} from './messaging-form.adapter';

// initialization of mixin factory

export const {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ResetErrorMessage: ResetErrorAdapterMessage,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeMessagingFormMessage extends actionableFactory.create<
  'Initialize Messaging Form',
  Record<string, never>
>('Initialize Messaging Form') {}

export class ClearSearchColleagueMessage extends actionableFactory.create<
  'Clear Search Colleague Message',
  Record<string, never>
>('Clear Search Colleague Message') {}

export class AddSelectedColleagueMessage extends actionableFactory.create<
  'Add Selected Colleague Message',
  { colleague: IColleagueEntity }
>('Add Selected Colleague Message') {}

export class RemoveSelectedColleagueMessage extends actionableFactory.create<
  'Remove Selected Colleague Message',
  { colleague: IColleagueEntity }
>('Remove Selected Colleague Message') {}

export class SetSelectedConversationIdMessage extends actionableFactory.create<
  'Set Selected Conversation Id Message',
  { id: number }
>('Set Selected Conversation Id Message') {}

export class SetSelectedRequestIdMessage extends actionableFactory.create<
  'Set Selected Request Id Message',
  { id: number }
>('Set Selected Request Id Message') {}

export class SetSearchStringMessage extends actionableFactory.create<
  'Set Search String Message',
  { search: string }
>('Set Search String Message') {}

export class AddNewCommentMessage extends actionableFactory.create<
  'Add New Comment Message',
  Record<string, never>
>('Add New Comment Message') {}

export class ClearNewMessageMessage extends actionableFactory.create<
  'Clear New Message Message',
  Record<string, never>
>('Clear New Message Message') {}

export type MessagingFormMessages =
  | InitializeMessagingFormMessage
  | ClearSearchColleagueMessage
  | AddSelectedColleagueMessage
  | RemoveSelectedColleagueMessage
  | SetSelectedConversationIdMessage
  | SetSelectedRequestIdMessage
  | SetSearchStringMessage
  | ClearNewMessageMessage
  | ResetErrorMessage
  | AlertErrorMessage;
