import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TEMP_FILE_UPLOAD_SERVICE } from '@locumsnest/components/src/lib/molecules/file-upload/opaque-tokens';
import { TempFileUploadServiceZen } from '@locumsnest/components/src/lib/molecules/file-upload/temp-file-upload-zen.service';

import { reducers } from './+state';
import { ZendeskRequestsEffects } from './+state/zendesk-requests.effects';
import { featureKey } from './+state/zendesk-requests.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature([ZendeskRequestsEffects]),
  ],
  providers: [
    {
      provide: TEMP_FILE_UPLOAD_SERVICE,
      useClass: TempFileUploadServiceZen,
    },
  ],
})
export class ZendeskRequestsModule {}
