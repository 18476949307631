import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IZendeskRequestsFeatureState } from './interfaces';
import { paginationAdapter } from './zendesk-requests.adapter';
import { featureKey, selectAll } from './zendesk-requests.reducer';

export const selectZendeskRequestsState =
  createFeatureSelector<IZendeskRequestsFeatureState>(featureKey);

export const selectZendeskRequestsEntityState = createSelector(
  selectZendeskRequestsState,
  (state) => state.entityState
);

export const selectAllEntities = createSelector(selectZendeskRequestsEntityState, selectAll);

export const selectSelectedRequest = createSelector(
  selectZendeskRequestsEntityState,
  (entityState) => entityState.selectedRequest
);

export const zendeskRequestsPaginationSelectors = paginationAdapter.paginationSelectors(
  selectZendeskRequestsState,
  null,
  'pagination',
  'entityState',
  false
);
