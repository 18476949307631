import { Injectable } from '@angular/core';
import { LocumsNestEndpointConfig } from 'apps/hospital-admin/src/app/core/constants';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { IZendeskCommentEntity } from './interfaces';

@Injectable({ providedIn: 'root' })
export class ZendeskCommentsPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IZendeskCommentEntity
> {
  protected override readonly endpoint = 'zendeskRequestComments';
  protected override readonly defaultPageSize = 50;
  protected override readonly defaultHeaders = { 'Content-Type': 'application/json' };

  addComment(requestId: number, message: string, attachmentTokens: string[]) {
    return this.update(
      requestId,
      { request: { comment: { body: message, uploads: attachmentTokens } } },
      {},
      { controllerResource: 'zendeskRequestsAddComment', skipSerializer: true },
    );
  }

  newTicket(subject: string, message: string, attachmentTokens: string[]) {
    return this.create<{}, IZendeskCommentEntity>(
      {
        request: {
          subject,
          comment: {
            body: message,
            uploads: attachmentTokens,
          },
        },
      },
      { pathParams: {}, controllerResource: 'zendeskRequestsAddComment', skipSerializer: true },
    );
  }
}
