import { createSelector } from '@ngrx/store';

import { selectLayoutState } from '../../../core/+state/layout.selectors';

export const selectShowNewMessage = createSelector(
  selectLayoutState,
  (layoutState) => layoutState.messagingUiState.showNewMessage
);

export const selectShowColleagueList = createSelector(
  selectLayoutState,
  (layoutState) => layoutState.messagingUiState.showColleagueList
);

export const selectSelectedAction = createSelector(
  selectLayoutState,
  (layoutState) => layoutState.messagingUiState.selectedAction
);

export const selectShowSectionCategories = createSelector(
  selectLayoutState,
  (layoutState) => layoutState.messagingUiState.showSectionCategories
);
