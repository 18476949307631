import { IColleagueEntity } from '../../../interfaces/api/messaging-entity';
import { IZendeskRequestEntity } from '../../../interfaces/api/zendesk-request-entity';
import {
  signalableFactory as actionableFactory,
  alertStateAdapter,
} from './messaging-form.adapter';

const { ResetErrorSignal } = alertStateAdapter.getSignals();

export class InitializeMessagingFormSignal extends actionableFactory.create<
  'Initialize Messaging Form',
  Record<string, never>
>('Initialize Messaging Form') {}

export class SubmitMessagingFormSignal extends actionableFactory.create<
  'Submit Messaging Form',
  Record<string, never>
>('Submit Messaging Form') {}

export class ClearSearchColleagueSignal extends actionableFactory.create<
  'Clear Search Colleague Signal',
  Record<string, never>
>('Clear Search Colleague Signal') {}

export class AddSelectedColleagueSignal extends actionableFactory.create<
  'Add Selected Colleague Signal',
  { colleague: IColleagueEntity }
>('Add Selected Colleague Signal') {}

export class RemoveSelectedColleagueSignal extends actionableFactory.create<
  'Remove Selected Colleague Signal',
  { colleague: IColleagueEntity }
>('Remove Selected Colleague Signal') {}

export class SetSelectedConversationIdSignal extends actionableFactory.create<
  'Set Selected Conversation Id Signal',
  { id: number }
>('Set Selected Conversation Id Signal') {}

export class SetSelectedRequestIdSignal extends actionableFactory.create<
  'Set Selected Request Id Signal',
  { request: IZendeskRequestEntity }
>('Set Selected Request Id Signal') {}

export class SetSearchStringSignal extends actionableFactory.create<
  'Set Search String Signal',
  { search: string }
>('Set Search String Signal') {}

export class SendMessageSignal extends actionableFactory.create<
  'Send Message Signal',
  Record<string, never>
>('Send Message Signal') {}

export class ResetMessagingAlertSignal extends ResetErrorSignal {}
