import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createEntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

import { IZendeskRequestEntity } from './interfaces';

const namespace = 'zendeskRequests';

const adapter = createEntityAdapter<IZendeskRequestEntity>({});

const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
const initialState = adapter.getInitialState({ selectedRequest: null });

const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export { adapter, initialState, paginationAdapter, signalableFactory };
