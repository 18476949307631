import { ISelectedActionEnum } from '../interfaces/messaging-ui-state';
import { messageableFactory as actionableFactory } from './messaging-ui.adapter';

export class InitializeMessagingUiMessage extends actionableFactory.create<
  'Initialize Messaging Ui Message',
  Record<string, never>
>('Initialize Messaging Ui Message') {}

export class UpdateShowNewMessageMessage extends actionableFactory.create<
  'Update Show New Message Message',
  { show?: boolean }
>('Update Show New Message Message') {}

export class UpdateShowColleagueListMessage extends actionableFactory.create<
  'Update Show Colleague List Message',
  Record<string, never>
>('Update Show Colleague List Message') {}

export class UpdateSelectedActionMessage extends actionableFactory.create<
  'Update Selected Action Message',
  { selectedAction?: ISelectedActionEnum }
>('Update Selected Action Message') {}

export class UpdateShowSectionCategoriesMessage extends actionableFactory.create<
  'Update Show Section Categories Message',
  { show: boolean }
>('Update Show Section Categories Message') {}

export type MessagingUiMessages =
  | InitializeMessagingUiMessage
  | UpdateShowNewMessageMessage
  | UpdateShowColleagueListMessage
  | UpdateSelectedActionMessage
  | UpdateShowSectionCategoriesMessage;
