import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IZendeskCommentsFeatureState } from './interfaces';
import { paginationAdapter } from './zendesk-comments.adapter';
import { featureKey, selectAll } from './zendesk-comments.reducer';

export const selectZendeskCommentsState =
  createFeatureSelector<IZendeskCommentsFeatureState>(featureKey);

export const selectZendeskCommentsEntityState = createSelector(
  selectZendeskCommentsState,
  (state) => state.entityState
);

export const selectAllEntities = createSelector(selectZendeskCommentsEntityState, selectAll);

export const zendeskCommentsPaginationSelectors = paginationAdapter.paginationSelectors(
  selectZendeskCommentsState,
  null,
  'pagination',
  'entityState',
  false
);

export const selectCommentsByRequestId = (requestId: number) =>
  createSelector(selectAllEntities, (comments) =>
    comments.filter((comment) => comment.requestId === requestId)
  );
