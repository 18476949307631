import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { map } from 'rxjs';

import { PaginatedStateService } from '@locumsnest/core/src';

import { IZendeskRequestEntity } from './interfaces';
import {
  paginationMessages,
  ResetZendeskRequestPaginationMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
  UpsertZendeskRequestPageMessage,
} from './zendesk-requests.messages';
import { ZendeskRequestsPersistenceService } from './zendesk-requests.persistence.service';
import {
  selectAllEntities,
  selectSelectedRequest,
  selectZendeskRequestsEntityState,
  zendeskRequestsPaginationSelectors,
} from './zendesk-requests.selectors';

@Injectable({ providedIn: 'root' })
export class ZendeskRequestsService extends PaginatedStateService<
  IZendeskRequestEntity,
  UpsertZendeskRequestPageMessage,
  ResetZendeskRequestPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(protected persistenceService: ZendeskRequestsPersistenceService) {
    super();
  }

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return zendeskRequestsPaginationSelectors;
  }

  get entityStateSelector() {
    return selectZendeskRequestsEntityState;
  }

  fetch() {
    return this.initializePagination('default', {}, { sortBy: 'updated_at', sortOrder: 'desc' });
  }

  getAllZendeskRequests() {
    return this.store
      .pipe(select(selectAllEntities))
      .pipe(
        map((requests) =>
          requests.sort((a, b) =>
            a.updatedAt > b.updatedAt ? -1 : a.updatedAt < b.updatedAt ? 1 : 0,
          ),
        ),
      );
  }

  getSelectedRequest() {
    return this.store.pipe(select(selectSelectedRequest));
  }

  fetchRequestById(id: number, showNotification: boolean) {
    return this.persistenceService
      .retrieve(id, { controllerResource: 'zendeskRequestsAddComment', skipSerializer: true })
      .pipe(
        map(
          (entity: any) =>
            new UpsertOneMessage({
              entity: { ...entity.request, hasUnreadMessages: showNotification },
            }),
        ),
      );
  }

  hasUnreadMessages() {
    return this.getAll().pipe(
      map((requests) => requests.filter((request) => request?.hasUnreadMessages).length > 0),
    );
  }
}
