import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { filter, map, Observable, switchMap } from 'rxjs';

import { IMessengerMessage } from '@locumsnest/components/src/lib/interfaces/message';
import { PaginatedStateService } from '@locumsnest/core/src';

import { MessagingService } from '../../../../../../apps/hospital-admin/src/app/messaging-panel/+state/messaging.service';
import { IZendeskCommentEntity } from './interfaces';
import {
  paginationMessages,
  ResetZendeskCommentPaginationMessage,
  UpsertMultipleMessage,
  UpsertZendeskCommentPageMessage,
} from './zendesk-comments.messages';
import { ZendeskCommentsPersistenceService } from './zendesk-comments.persistence.service';
import {
  selectAllEntities,
  selectCommentsByRequestId,
  selectZendeskCommentsEntityState,
  zendeskCommentsPaginationSelectors,
} from './zendesk-comments.selectors';

@Injectable({ providedIn: 'root' })
export class ZendeskCommentsService extends PaginatedStateService<
  IZendeskCommentEntity,
  UpsertZendeskCommentPageMessage,
  ResetZendeskCommentPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected persistenceService: ZendeskCommentsPersistenceService,
    private messagingService: MessagingService,
  ) {
    super();
  }

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return zendeskCommentsPaginationSelectors;
  }

  get entityStateSelector() {
    return selectZendeskCommentsEntityState;
  }

  fetch(requestId: number) {
    return this.initializePagination(requestId.toString(), { pathParams: { requestId } });
  }

  getAllZendeskComments() {
    return this.store.pipe(select(selectAllEntities));
  }

  getCommentsByRequestId(requestId: number) {
    return this.store.pipe(select(selectCommentsByRequestId(requestId)));
  }

  getSelectedRequestComments(): Observable<IMessengerMessage[]> {
    return this.messagingService.getSelectedRequestId().pipe(
      filter((x) => !!x),
      switchMap((selectedRequestId) =>
        this.getCommentsByRequestId(selectedRequestId).pipe(
          map((comments) =>
            comments.map((comment) => ({
              sender: {
                id: comment?.authorId,
                firstName: comment?.user.name,
              },
              messageText: comment?.htmlBody,
              createdAt: new Date(comment?.createdAt),
              isOfficer: !comment?.user.agent,
              photoUrl: null,
              id: comment?.id,
              attachments: comment.attachments,
            })),
          ),
        ),
      ),
    );
  }

  addComment(requestId: number, message: string, attachmentTokens: string[]) {
    return this.persistenceService.addComment(requestId, message, attachmentTokens);
  }

  newTicket(subject: string, message: string, attachmentTokens: string[]) {
    return this.persistenceService.newTicket(subject, message, attachmentTokens);
  }
}
