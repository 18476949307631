import { IMessagingUiState, ISelectedActionEnum } from '../interfaces/messaging-ui-state';
import {
  InitializeMessagingUiMessage,
  MessagingUiMessages,
  UpdateSelectedActionMessage,
  UpdateShowColleagueListMessage,
  UpdateShowNewMessageMessage,
  UpdateShowSectionCategoriesMessage,
} from './messaging-ui.messages';

export * from './messaging-ui.selectors';

export const INITIAL_UI_STATE: IMessagingUiState = {
  showNewMessage: false,
  showColleagueList: false,
  selectedAction: ISelectedActionEnum.FAQ,
  showSectionCategories: false,
};

export function reducer(state: IMessagingUiState = INITIAL_UI_STATE, action: MessagingUiMessages) {
  switch (action.type) {
    case InitializeMessagingUiMessage.TYPE:
      state = {
        ...INITIAL_UI_STATE,
      };
      break;
    case UpdateShowNewMessageMessage.TYPE: {
      const { payload } = action as UpdateShowNewMessageMessage;
      state = {
        ...state,
        showNewMessage: payload.show ? payload.show : !state.showNewMessage,
      };
      break;
    }
    case UpdateShowColleagueListMessage.TYPE:
      state = {
        ...state,
        showColleagueList: !state.showColleagueList,
      };
      break;
    case UpdateShowSectionCategoriesMessage.TYPE: {
      const { payload } = action as UpdateShowSectionCategoriesMessage;
      state = {
        ...state,
        showSectionCategories: payload.show,
      };
      break;
    }
    case UpdateSelectedActionMessage.TYPE: {
      const { payload } = action as UpdateSelectedActionMessage;
      state = {
        ...state,
        selectedAction: payload.selectedAction,
      };
      break;
    }

    default:
      break;
  }

  return state;
}
