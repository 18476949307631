import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { IZendeskRequestEntity } from './interfaces';
import { paginationAdapter } from './zendesk-requests.adapter';

export const {
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
  UpsertMultiplePagesMessage,
  UpsertPageMessage,
} = paginationAdapter.getMessages();

export const paginationMessages = paginationAdapter.getMessages();

export class UpsertZendeskRequestPageMessage extends UpsertPageMessage {}
export class UpsertZendeskRequestMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetZendeskRequestPaginationMessage extends ResetPaginationMessage {}

export enum ZendeskRequestMessageTypes {
  SET_COLLECTION = '[ZendeskRequest][Message] Set Collection',
  ADD_ONE = '[ZendeskRequest][Message] Add One',
  UPDATE_ONE = '[ZendeskRequest][Message] Update One',
  UPSERT_ONE = '[ZendeskRequest][Message] Upsert One',
  DELETE_ONE = '[ZendeskRequest][Message] Delete One',
  ADD_MULTIPLE = '[ZendeskRequest][Message] Add All',
  DELETE_MULTIPLE = '[ZendeskRequest][Message] Delete Many',
  UPSERT_MULTIPLE = '[ZendeskRequest][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ZendeskRequest][Message] Update Many',
  REMOVE_ALL = '[ZendeskRequest][Message] Remove All',
  SET_SELECTED = '[ZendeskRequest][Message] Set Selected',
  SET_SECTIONS = '[ZendeskRequest][Message] Set Sections',
}
export class SetCollectionMessage implements Action {
  readonly type = ZendeskRequestMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IZendeskRequestEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ZendeskRequestMessageTypes.ADD_ONE;
  constructor(public payload: { entity: IZendeskRequestEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ZendeskRequestMessageTypes.UPSERT_ONE;
  constructor(public payload: { entity: IZendeskRequestEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ZendeskRequestMessageTypes.ADD_MULTIPLE;
  constructor(public payload: { entities: IZendeskRequestEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ZendeskRequestMessageTypes.UPSERT_MULTIPLE;
  constructor(public payload: { entities: IZendeskRequestEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ZendeskRequestMessageTypes.UPDATE_ONE;
  constructor(public payload: { entity: Update<IZendeskRequestEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ZendeskRequestMessageTypes.UPDATE_MULTIPLE;
  constructor(public payload: { entities: Update<IZendeskRequestEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ZendeskRequestMessageTypes.DELETE_ONE;
  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ZendeskRequestMessageTypes.DELETE_MULTIPLE;
  constructor(public payload: { ids: number[] }) {}
}

export class RemoveAllMessage implements Action {
  readonly type = ZendeskRequestMessageTypes.REMOVE_ALL;
}

export type ZendeskRequestMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | RemoveAllMessage;
