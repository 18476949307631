import { signalableFactory } from './zendesk-requests.adapter';

export class InitializeRequestsSignal extends signalableFactory.create<'Initialize Requests Signal'>(
  'Initialize Requests Signal',
) {}

export class FetchRequestByIdSignal extends signalableFactory.create<
  'Fetch Request By Id Signal',
  { id: number; showNotification: boolean }
>('Fetch Request By Id Signal') {}
