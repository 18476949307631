import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { unbox } from 'ngrx-forms';
import { debounceTime, exhaustMap, merge, mergeMap, of, switchMap } from 'rxjs';

import { ZendeskCommentsService } from '@locumsnest/zendesk/comments/src/lib/+state/zendesk-comments.service';
import { GetCommentsForRequestSignal } from '@locumsnest/zendesk/comments/src/lib/+state/zendesk-comments.signals';
import {
  FetchRequestByIdSignal,
  InitializeRequestsSignal,
  UpsertOneMessage,
} from '@locumsnest/zendesk/requests/src';

import { UpdateShowNewMessageMessage } from '../messaging-ui/messaging-ui.messages';
import {
  AddSelectedColleagueMessage,
  ClearNewMessageMessage,
  ClearSearchColleagueMessage,
  InitializeMessagingFormMessage,
  RemoveSelectedColleagueMessage,
  SetSearchStringMessage,
  SetSelectedConversationIdMessage,
  SetSelectedRequestIdMessage,
} from './messaging-form.messages';
import { selectMessagingFormState } from './messaging-form.selectors';
import {
  AddSelectedColleagueSignal,
  ClearSearchColleagueSignal,
  InitializeMessagingFormSignal,
  RemoveSelectedColleagueSignal,
  SendMessageSignal,
  SetSearchStringSignal,
  SetSelectedConversationIdSignal,
  SetSelectedRequestIdSignal,
} from './messaging-form.signals';

@Injectable()
export class MessagingFormEffects {
  initializeMessagingFormSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InitializeMessagingFormSignal>(InitializeMessagingFormSignal.TYPE),
      mergeMap(() => of(new InitializeMessagingFormMessage({}))),
    ),
  );

  clearSearchColleagueSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ClearSearchColleagueSignal>(ClearSearchColleagueSignal.TYPE),
      mergeMap(() => of(new ClearSearchColleagueMessage({}))),
    ),
  );

  addSelectedColleagueSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddSelectedColleagueSignal.TYPE),
      mergeMap((action) => {
        const { payload } = action as AddSelectedColleagueSignal;

        return of(new AddSelectedColleagueMessage({ colleague: payload.colleague }));
      }),
    ),
  );

  removeSelectedColleagueSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveSelectedColleagueSignal.TYPE),
      mergeMap((action) => {
        const { payload } = action as RemoveSelectedColleagueSignal;

        return of(new RemoveSelectedColleagueMessage({ colleague: payload.colleague }));
      }),
    ),
  );

  setSelectedConversationIdSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetSelectedConversationIdSignal.TYPE),
      mergeMap((action) => {
        const { payload } = action as SetSelectedConversationIdSignal;

        return merge(
          of(new SetSelectedConversationIdMessage({ id: payload.id })),
          of(new UpdateShowNewMessageMessage({ show: false })),
        );
      }),
    ),
  );

  setSelectedRequestIdSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetSelectedRequestIdSignal.TYPE),
      mergeMap((action) => {
        const { payload } = action as SetSelectedRequestIdSignal;
        return merge(
          of(new InitializeMessagingFormMessage({})),
          of(new SetSelectedRequestIdMessage({ id: payload.request.id })),
          of(new UpsertOneMessage({ entity: { ...payload.request, hasUnreadMessages: false } })),
        );
      }),
    ),
  );

  setSearchStringSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetSearchStringSignal>(SetSearchStringSignal.TYPE),
      debounceTime(1000),
      switchMap((action) => {
        const { payload } = action;
        return of(new SetSearchStringMessage({ search: payload.search }));
      }),
    ),
  );

  sendMessageSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SendMessageSignal>(SendMessageSignal.TYPE),
      concatLatestFrom(() => [this.store.pipe(select(selectMessagingFormState))]),
      exhaustMap(([, formState]) => {
        if (formState.value.selectedRequestId && formState.value.newMessage) {
          return this.zendeskCommentsService
            .addComment(
              formState.value.selectedRequestId,
              formState.value.newMessage,
              unbox(formState.value.fileTokens),
            )
            .pipe(
              mergeMap(() =>
                merge(
                  of(
                    new FetchRequestByIdSignal({
                      id: formState.value.selectedRequestId,
                      showNotification: false,
                    }),
                  ),
                  of(new ClearNewMessageMessage({})),
                  of(
                    new GetCommentsForRequestSignal({
                      requestId: formState.value.selectedRequestId,
                    }),
                  ),
                ),
              ),
            );
        } else if (formState.value.newMessage) {
          return this.zendeskCommentsService
            .newTicket(
              formState.value.subject,
              formState.value.newMessage,
              unbox(formState.value.fileTokens),
            )
            .pipe(
              mergeMap((response: any) =>
                merge(
                  of(new UpdateShowNewMessageMessage({ show: false })),
                  of(new ClearNewMessageMessage({})),
                  of(new SetSelectedRequestIdMessage({ id: response.request.id })),
                  of(new GetCommentsForRequestSignal({ requestId: response.request.id })),
                  of(new InitializeRequestsSignal({})),
                ),
              ),
            );
        }
        return of(new ClearNewMessageMessage({}));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private zendeskCommentsService: ZendeskCommentsService,
  ) {}
}
