import { IMessengerMessage } from '@locumsnest/components/src/lib/interfaces/message';
import { IUser } from '@locumsnest/components/src/lib/interfaces/user';

export interface IMessagingUiState {
  readonly showNewMessage: boolean;
  readonly showColleagueList: boolean;
  readonly selectedAction: ISelectedActionEnum;
  readonly showSectionCategories: boolean;
}

export interface IMessageListItem {
  readonly id: number;
  readonly receivers: IUser[];
  readonly messages: IMessengerMessage[];
  readonly createdAt: Date;
  readonly updatedAt?: Date;
}

export enum ISelectedActionEnum {
  FAQ,
  SUPPORT,
  MESSAGE,
}
