import { Injectable } from '@angular/core';
import { LocumsNestEndpointConfig } from 'apps/hospital-admin/src/app/core/constants';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { IZendeskRequestEntity } from './interfaces';

@Injectable({ providedIn: 'root' })
export class ZendeskRequestsPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IZendeskRequestEntity
> {
  protected override readonly endpoint = 'zendeskRequests';
  protected override readonly defaultPageSize = 25;
  protected override readonly defaultHeaders = { 'Content-Type': 'application/json' };
}
