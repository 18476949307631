import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import {
  selectMessagingFormState,
  selectSearchString,
  selectSelectedRequestId,
} from './messaging-form';
import {
  selectSelectedAction,
  selectShowColleagueList,
  selectShowNewMessage,
  selectShowSectionCategories,
} from './messaging-ui';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  constructor(private store: Store) {}

  getShowNewMessage() {
    return this.store.pipe(select(selectShowNewMessage));
  }

  getShowColleagueList() {
    return this.store.pipe(select(selectShowColleagueList));
  }

  getMessagingForm() {
    return this.store.pipe(select(selectMessagingFormState));
  }

  getSelectedRequestId() {
    return this.store.pipe(select(selectSelectedRequestId));
  }

  getSearchString() {
    return this.store.pipe(select(selectSearchString));
  }

  getSelectedAction() {
    return this.store.pipe(select(selectSelectedAction));
  }

  getShowSectionCategories() {
    return this.store.pipe(select(selectShowSectionCategories));
  }
}
