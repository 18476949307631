import { createSelector } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';

import { selectLayoutState } from '../../../core/+state/layout.selectors';
import { IMessagingEntity } from '../../../interfaces/api/messaging-entity';
import { IMessagingFormState } from '../interfaces/messaging-form-state';

export const getEntityStateFromFormState = (
  formState: FormGroupState<IMessagingFormState>
): IMessagingEntity => formState.value;

export const selectMessagingFormState = createSelector(
  selectLayoutState,
  (state) => state.messagingFormState
);

export const selectSelectedRequestId = createSelector(
  selectMessagingFormState,
  (state) => state.value.selectedRequestId
);

export const selectSearchString = createSelector(
  selectMessagingFormState,
  (state) => state.value.searchString
);

export const selectNewMessage = createSelector(
  selectMessagingFormState,
  (state) => state.value.newMessage
);

export const selectSubject = createSelector(
  selectMessagingFormState,
  (state) => state.value.subject
);
