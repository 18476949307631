import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IZendeskAttachment } from 'apps/hospital-admin/src/app/interfaces/api/zendesk-comment-entity';
import { map } from 'rxjs';

import { HttpApiPersistenceService, MimeType } from '@locumsnest/core/src';

import {
  ITempFileUploadService,
  ITempUploadEndpointConfig,
  TempFileUploadResponse,
} from './interfaces';

@Injectable()
export class TempFileUploadServiceZen
  extends HttpApiPersistenceService<ITempUploadEndpointConfig, TempFileUploadResponse>
  implements ITempFileUploadService
{
  protected readonly endpoint = 'zendeskFileUpload';
  protected override readonly defaultHeaders = { 'Content-Type': 'application/json' };

  upload(file: File): any {
    return this.create<File, { upload: { token: string; attachment: IZendeskAttachment } }, any>(
      file,
      null,
      {
        params: {
          filename: file.name,
        },
        headers: {
          'Content-Type': file.type,
        },
      },
    ).pipe(
      map((res) => ({
        type: HttpEventType.Response,
        body: {
          token: res.upload.token,
          filePath: res.upload.token,
          downloadLink: res.upload.attachment.contentUrl,
        },
      })),
    );
  }

  deleteFile(fileName: string) {
    return this.delete<any>(fileName, {}, { accept: MimeType.json });
  }
}
