import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs';

import { ZendeskRequestsService } from './zendesk-requests.service';
import { FetchRequestByIdSignal, InitializeRequestsSignal } from './zendesk-requests.signals';

@Injectable()
export class ZendeskRequestsEffects {
  getRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InitializeRequestsSignal>(InitializeRequestsSignal.TYPE),
      switchMap(() => this.zendeskRequestsService.fetch()),
    ),
  );

  fetchRequestByIdSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchRequestByIdSignal>(FetchRequestByIdSignal.TYPE),
      switchMap((action) => {
        const { payload } = action;
        return this.zendeskRequestsService.fetchRequestById(payload.id, payload.showNotification);
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private zendeskRequestsService: ZendeskRequestsService,
  ) {}
}
