import {
  addArrayControl,
  box,
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  markAsPristine,
  markAsUntouched,
  removeArrayControl,
  setValue,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';

import { ATTACHMENTS_LENGTH } from '.';
import { IMessagingFormState } from '../interfaces/messaging-form-state';
import {
  AddSelectedColleagueMessage,
  ClearNewMessageMessage,
  ClearSearchColleagueMessage,
  InitializeMessagingFormMessage,
  MessagingFormMessages,
  RemoveSelectedColleagueMessage,
  SetSearchStringMessage,
  SetSelectedConversationIdMessage,
  SetSelectedRequestIdMessage,
} from './messaging-form.messages';
import { isFileUploading, isSubjectValid } from './messaging-form.validators';

export * from './messaging-form.selectors';
export const FORM_ID = 'MESSAGING_FORM';
export type State = FormGroupState<IMessagingFormState>;

export const INITIAL_FORM_STATE = createFormGroupState<IMessagingFormState>(FORM_ID, {
  searchConversation: '',
  newMessage: '',
  searchColleague: '',
  selectedColleagueList: [],
  selectedConversationId: null,
  selectedRequestId: null,
  searchString: '',
  subject: '',
  fileTokens: box([]),
});

const formValidationReducer = (formState: FormGroupState<IMessagingFormState>) =>
  updateGroup<IMessagingFormState>({
    newMessage: validate<string>([required, maxLength(2000)]),
    subject: (subject, state) => validate<string>([maxLength(150), isSubjectValid(state)])(subject),
    fileTokens: validate([isFileUploading, maxLength(ATTACHMENTS_LENGTH)]),
  })(formState);

export const formStateReducer = createFormStateReducerWithUpdate<IMessagingFormState>([]);

export function formReducer(
  state: FormGroupState<IMessagingFormState> = INITIAL_FORM_STATE,
  action: MessagingFormMessages,
) {
  switch (action.type) {
    case InitializeMessagingFormMessage.TYPE:
      state = createFormGroupState<IMessagingFormState>(FORM_ID, INITIAL_FORM_STATE.value);
      break;
    case ClearSearchColleagueMessage.TYPE:
      state = updateGroup<IMessagingFormState>({
        searchColleague: (searchColleague) => setValue('')(searchColleague),
      })(state);
      break;
    case AddSelectedColleagueMessage.TYPE:
      state = updateGroup<IMessagingFormState>({
        selectedColleagueList: addArrayControl(
          (action as AddSelectedColleagueMessage).payload.colleague,
        ),
      })(state);
      break;
    case RemoveSelectedColleagueMessage.TYPE:
      {
        const colleagueIndex = state.controls.selectedColleagueList.value.findIndex(
          (colleague) =>
            colleague.id === (action as RemoveSelectedColleagueMessage).payload.colleague.id,
        );

        state = updateGroup<IMessagingFormState>({
          selectedColleagueList: removeArrayControl(colleagueIndex),
        })(state);
      }
      break;
    case SetSelectedConversationIdMessage.TYPE: {
      const { payload } = action as InstanceType<typeof SetSelectedConversationIdMessage>;

      state = updateGroup<IMessagingFormState>({
        selectedConversationId: (selectedConversationId) =>
          setValue(payload.id)(selectedConversationId),
      })(state);
      break;
    }

    case SetSearchStringMessage.TYPE: {
      const { payload } = action as InstanceType<typeof SetSearchStringMessage>;

      state = updateGroup<IMessagingFormState>({
        searchString: (searchString) => setValue(payload.search)(searchString),
      })(state);
      break;
    }

    case SetSelectedRequestIdMessage.TYPE: {
      const { payload } = action as InstanceType<typeof SetSelectedRequestIdMessage>;

      state = updateGroup<IMessagingFormState>({
        selectedRequestId: (selectedRequestId) => setValue(payload.id)(selectedRequestId),
      })(state);
      break;
    }
    case ClearNewMessageMessage.TYPE: {
      state = updateGroup<IMessagingFormState>({
        newMessage: (newMessage) => setValue('')(newMessage),
        subject: (subject) => setValue('')(subject),
        fileTokens: (fileTokens) => setValue(box([]))(fileTokens),
      })(state);

      state = markAsUntouched(state);
      state = markAsPristine(state);
      break;
    }
  }

  state = formStateReducer(state, action);
  state = formValidationReducer(state);

  return state;
}

export function reducer(state: State, action) {
  const form = formReducer(state, action);
  return {
    ...form,
  };
}
