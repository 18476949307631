import { Boxed, FormGroupState, unbox, ValidationErrors } from 'ngrx-forms';

import { IMessagingFormState } from '../interfaces/messaging-form-state';

export const isSubjectValid = (state: FormGroupState<IMessagingFormState>) => () =>
  !state.value.selectedRequestId && !state.value.subject
    ? {
        required: {
          actual: true,
        },
      }
    : null;

export function isFileUploading<T>(value: Boxed<T[]>): ValidationErrors {
  if (unbox(value).some((token) => token === 'uploading'))
    return {
      fileUploading: true,
    };
  return null;
}
